import { EVENT_TYPE } from "../storecontextlayer/enums/event-type";
import { fetchTrackingCookieService } from "./services/tracking-cookie";
import { addTmsTrackingForChooseMyStoreButton } from "./tracking-tms";
export function addContextBannerEvent() {
  var contextBannerSelector = document.querySelector("[data-selectorid='context-banner']");
  var postcodeBannerSelector = contextBannerSelector === null || contextBannerSelector === void 0 ? void 0 : contextBannerSelector.querySelector(".js-context-postcode-button");
  var storeHeaderSelector = contextBannerSelector.querySelector(".js-context-store-name-button");
  var openContextLayerEvent = new Event("openStoreContextLayer");
  postcodeBannerSelector === null || postcodeBannerSelector === void 0 || postcodeBannerSelector.addEventListener(EVENT_TYPE.CLICK, function () {
    addTmsTrackingOnContextBanner();
    window.dispatchEvent(openContextLayerEvent);
  });
  storeHeaderSelector === null || storeHeaderSelector === void 0 || storeHeaderSelector.addEventListener(EVENT_TYPE.CLICK, function () {
    addTmsTrackingOnContextBanner();
    window.dispatchEvent(openContextLayerEvent);
  });
}
function addTmsTrackingOnContextBanner() {
  var buttonLocationValue = "Header";
  fetchTrackingCookieService(buttonLocationValue, function () {
    addTmsTrackingForChooseMyStoreButton("Choose my store", "Contextualisation", buttonLocationValue);
  });
}